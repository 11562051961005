<template>
  <div class="windowHeader">
    <span class="headerText"><slot></slot></span>
    <div
      class="customerService customerServiceForLoading"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#FFFFFF"
      @click.stop="chatWithCustomerService"
    ></div>
  </div>
</template>
<script>
import { createCustomerGroup } from "@/api/rest";
export default {
  name: "windowHeader",
  data() {
    return {
      // 拉取客服群状态
      loading: false,
    };
  },
  methods: {
    // 和客服聊天
    async chatWithCustomerService() {
      // 如果处于未登录状态，则先登陆游客账号
      if (!this.$store.getters.userInfo.id) {
        this.loading = true;
        await this.handlerInitToursits();
        this.loading = false;
      } else {
        let userCatch = this.$store.getters.userInfo;
        if (userCatch.groupId) {
          this.$openIM({
            id: userCatch.groupId,
            displayName: userCatch.groupName,
            avatar: userCatch.groupHeader,
            isGroup: true,
          });
        } else if (userCatch.id) {
          const TOKEN = "BF03DF03D6925CDsf555828633A096D0C234";
          const result = await createCustomerGroup(TOKEN).catch(() => {});
          if (result.code == 200) {
            const { groupHeader, groupId, groupName } = result.data.data;
            // 2.打开IM
            setTimeout(() => {
              this.$openIM({
                id: groupId,
                displayName: groupName,
                avatar: groupHeader,
                isGroup: true,
              });
            }, 100);
          }
        }
      }
      this.$store.dispatch("commitCloseLoginAndRegisterDialog");
      this.$store.commit("setEmail_loginAndRegisterDialog", false);
    },
  },
};
</script>
<style lang="stylus" scoped>
.windowHeader
  width 100%;
  height 30px;
  display flex;
  align-items center;
  justify-content space-between;
  .headerText
    font-size 18px;
    color #343434;
  .customerService
    width 27px;
    height 24px;
    cursor pointer;
    background url('../../../assets/images/newLogin/customerService.png') no-repeat;
    background-size 100% 100%;
</style>
<style lang="stylus">
.customerServiceForLoading
  .el-loading-spinner
    margin-top -10px !important;
</style>
