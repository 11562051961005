var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "windowHeader" }, [
    _c("span", { staticClass: "headerText" }, [_vm._t("default")], 2),
    _c("div", {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "customerService customerServiceForLoading",
      attrs: {
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "#FFFFFF"
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.chatWithCustomerService.apply(null, arguments)
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }